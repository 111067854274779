export const questCervezaMobile = [
    {
        value: "Cocinando o almorzando.",
        label: "Cocinando o almorzando.",
    },
    {
        value: "Cuando me quiero relajar.",
        label: "Cuando me quiero relajar.",
    },
    {
        value: "Con amigxs en una casa o en un bar.",
        label: "Con amigxs en una casa o en un bar.",
    },
    {
        value: "En una fiesta, after, pre, etc.",
        label: "En una fiesta, after, pre, etc.",
    },
    {
        value: "No tomo cerveza.",
        label: "No tomo cerveza.",
    },
];

export const questCerveza = [
    {
        value: "Cocinando o almorzando.",
        label: "Cocinando o almorzando.",
    },
    {
        value: "Cuando me quiero relajar.",
        label: "Cuando me quiero relajar.",
    },
    {
        value: "Con amigxs en una casa o en un bar.",
        label: "Con amigxs en una casa o en un bar.",
    },
    {
        value: "En una fiesta, after, pre, etc.",
        label: "En una fiesta, after, pre, etc.",
    },
    {
        value: "Trasladándome a algún lugar / haciendo mandados.",
        label: "Trasladándome a algún lugar / haciendo mandados.",
    },
    {
        value: "Mientras trabajo.",
        label: "Mientras trabajo.",
    },
    {
        value: "No tomo cerveza.",
        label: "No tomo cerveza.",
    },
];

export const provincias = [
    { value: "Buenos Aires", label: "Buenos Aires" },
    { value: "Capital Federal", label: "Capital Federal" },
    { value: "Chaco", label: "Chaco" },
    { value: "Córdoba", label: "Córdoba" },
    { value: "Chubut", label: "Chubut" },
    { value: "Catamarca", label: "Catamarca" },
    { value: "Corrientes", label: "Corrientes" },
    { value: "Entre Ríos", label: "Entre Ríos" },
    { value: "Formosa", label: "Formosa" },
    { value: "Jujuy", label: "Jujuy" },
    { value: "La Pampa", label: "La Pampa" },
    { value: "La Rioja", label: "La Rioja" },
    { value: "Mendoza", label: "Mendoza" },
    { value: "Misiones", label: "Misiones" },
    { value: "Neuquén", label: "Neuquén" },
    { value: "Río Negro", label: "Río Negro" },
    { value: "Salta", label: "Salta" },
    { value: "San Juan", label: "San Juan" },
    { value: "Santa Cruz", label: "Santa Cruz" },
    { value: "Santa Fe", label: "Santa Fe" },
    { value: "San Luis", label: "San Luis" },
    { value: "Santiago del Estero", label: "Santiago del Estero" },
    { value: "Tierra del Fuego", label: "Tierra del Fuego" },
    { value: "Tucumán", label: "Tucumán" },
];
