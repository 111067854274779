import React, { useEffect, useState } from "react";
import styles from "./../../styles/popup.module.scss";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Checkbox27 from "../Checkbox";
import Modal from "react-modal";
import SelectPopup from "../SelectPopup";
import { IsMobileDevice, formatDate, validateDOB } from "../../utils/functions";
import { provincias, questCerveza, questCervezaMobile } from "../../data/popUp";
import { localidades } from "../../data/proviciasylocalidades";
import PopUpSuccess from "./PopUpSuccess";
import Cookies from "js-cookie";
import closeIcon from "../../images/close.svg";

const SignupSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, "Demasiado corto")
    .max(50, "Excede los caracteres")
    .required("Falta completar"),
  instagram: Yup.string().required("Falta completar"),
  date: Yup.string().required("Falta completar"),
  email: Yup.string()
    .email("El email es incorrecto")
    .required("Falta completar"),
  state: Yup.string()
    .max(50, "Excede los caracteres")
    .required("Falta completar"),
  questOne: Yup.string()
    .max(50, "Excede los caracteres")
    .required("Falta completar"),
  questTwo: Yup.string()
    .max(50, "Excede los caracteres")
    .required("Falta completar"),
  questThree: Yup.string()
    .max(50, "Excede los caracteres")
    .required("Falta completar"),
  checked: Yup.bool().oneOf(
    [true],
    "Los terminos y condiciones deben ser aceptados."
  ),
});
export default function PopUp({ isLegalAge }) {
  const [modalIsOpen, setIsOpen] = useState(true);
  const [data, setData] = useState();
  const [isValidDate, setIsValidDate] = useState(false);
  const [terms, setTerms] = useState(false);
  const [errorTerms, setErrorTerms] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [cities, setCities] = useState([]);
  const [provincia, setProvincia] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setIsMobileDevice(IsMobileDevice());
    // console.log(Cookies.get("age"));
    if (Cookies.get("age")) {
      setIsOpen(true);
    }
    if (Cookies.get("popup")) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    const localidadesArr = Object.entries(localidades);

    if (provincia) {
      const filter = localidadesArr.filter(([key, value]) => {
        return key === provincia;
      });
      const justStrings = Object.fromEntries(filter);
      const citiesArr = justStrings[provincia];
      const formatedCities = citiesArr?.map((provincia) => ({
        value: provincia,
        label: provincia,
      }));
      setCities(formatedCities);
    }
  }, [provincia]);

  function closeModal() {
    setIsOpen(false);
  }

  const validatedFields = (errors, touched) => {
    const validation =
      (errors.fullName && touched.fullName) ||
      (errors.date && touched.date) ||
      // !isValidDate ||
      (errors.email && touched.email) ||
      (errors.state && touched.state) ||
      (errors.instagram && touched.instagram) ||
      (errors.questOne && touched.questOne) ||
      (errors.questTwo && touched.questTwo) ||
      (errors.questThree && touched.questThree);

    setError(validation);
  };

  return (
    <div>
      {isLegalAge ? (
        <>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className={`${styles.containerModal} ${styles.bgImage}`}
            contentLabel="PopUp Modal"
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={false}
            overlayClassName={styles.modalOverlay}
          >
            {/*<div className={styles.closeBtnCont}>*/}
            {/*  <button>*/}
            {/*    <img*/}
            {/*      className={styles.closeBtn}*/}
            {/*      src={closeIcon}*/}
            {/*      alt="close button"*/}
            {/*      onClick={closeModal}*/}
            {/*    ></img>*/}
            {/*  </button>*/}
            {/*</div>*/}

            <div className={styles.wrap}>
              {isSuccess ? (
                <PopUpSuccess
                  isMobile={isMobileDevice}
                  closeModal={closeModal}
                />
              ) : (
                <Formik
                  initialValues={{
                    fullName: "",
                    date: "",
                    email: "",
                    state: "",
                    localidad: "",
                    instagram: "",
                    questOne: "",
                    questTwo: "",
                    questThree: "",
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={async (values) => {
                    setIsSubmitting(true);
                    const td = Cookies.get("_td");
                    let newValues = {
                      ...values,
                    };

                    const date = new Date();
                    const year = date.getFullYear();

                    if (newValues.date.length < 10) return setErrorDate(true);
                    if (Number(newValues.date.split("/")[0]) > 31)
                      return setErrorDate(true);
                    if (Number(newValues.date.split("/")[1]) > 12)
                      return setErrorDate(true);
                    if (Number(newValues.date.split("/")[2]) >= year)
                      return setErrorDate(true);
                    setErrorDate(false);
                    if (terms) {
                      let data = {
                        fullName: newValues.fullName,
                        date: newValues.date,
                        email: newValues.email,
                        state: newValues.state,
                        localidad: newValues.localidad,
                        instagram: newValues.instagram,
                        questOne: newValues.questOne,
                        questTwo: newValues.questTwo,
                        questThree: newValues.questThree,
                        td: td,
                      };
                      setData(data);

                      const apiUrl =
                        "https://wacerveza27back-prd.azurewebsites.net";
                      // const apiUrl = "http://localhost:8080";

                      const popupUrl = `${apiUrl}/popup`;
                      const popupOptions = {
                        method: "POST",
                        body: JSON.stringify(data),
                        // credentials: "include",
                        headers: {
                          "Content-Type": "application/json",
                        },
                      };
                      try {
                        window.dotq = window.dotq || [];
                        window.dotq.push({
                          projectId: "10000",
                          properties: {
                            pixelId: "10167605",
                            qstrings: {
                              et: "custom",
                              ec: "successpgcerveza27",
                            },
                          },
                        });
                        const response = await fetch(popupUrl, popupOptions);
                        if (response.ok) {
                          setIsSuccess(true);
                          window.dataLayer.push({
                            event: "GAEvent",
                            event_category: "Form",
                            event_action: "Success",
                            event_label: "",
                            interaction: true,
                            component_name: "boton_enviar",
                            userUid: td,
                          });
                          Cookies.set("popup", true, {
                            expires: 7,
                          });
                          if (typeof window !== "undefined") {
                            if (window.fbq != null) {
                              window.fbq("track", "CompleteRegistration");
                            }
                          }
                        }
                      } catch (err) {
                        console.log(err);
                      }
                    } else {
                      setErrorTerms(true);
                      window.dataLayer.push({
                        event: "GAEvent",
                        event_category: "Form",
                        event_action: "Error",
                        event_label: "Falta Aceptar TyC",
                        interaction: true,
                        component_name: "boton_enviar",
                      });
                    }
                  }}
                >
                  {({
                    errors,
                    touched,
                    setFieldValue,
                    values,
                    isSubmitting,
                  }) => {
                    if (isSubmitting) {
                      console.log("error", errors);
                      if (errors.fullName)
                        window.dataLayer.push({
                          event: "GAEvent",
                          event_category: "Form",
                          event_action: "Error",
                          event_label: "Falta completar nombre y apellido",
                          interaction: true,
                          component_name: "boton_enviar",
                        });
                      if (errors.date)
                        window.dataLayer.push({
                          event: "GAEvent",
                          event_category: "Form",
                          event_action: "Error",
                          event_label: "Falta completar fecha de nacimiento",
                          interaction: true,
                          component_name: "boton_enviar",
                        });
                      if (errors.email)
                        window.dataLayer.push({
                          event: "GAEvent",
                          event_category: "Form",
                          event_action: "Error",
                          event_label: "Falta completar email",
                          interaction: true,
                          component_name: "boton_enviar",
                        });
                      if (errors.state)
                        window.dataLayer.push({
                          event: "GAEvent",
                          event_category: "Form",
                          event_action: "Error",
                          event_label: "Falta completar provincia",
                          interaction: true,
                          component_name: "boton_enviar",
                        });
                      if (errors.questOne)
                        window.dataLayer.push({
                          event: "GAEvent",
                          event_category: "Form",
                          event_action: "Error",
                          event_label: "Falta completar Primera pregunta",
                          interaction: true,
                          component_name: "boton_enviar",
                        });
                      if (errors.questTwo)
                        window.dataLayer.push({
                          event: "GAEvent",
                          event_category: "Form",
                          event_action: "Error",
                          event_label: "Falta completar Segunda pregunta",
                          interaction: true,
                          component_name: "boton_enviar",
                        });
                      if (errors.questThree)
                        window.dataLayer.push({
                          event: "GAEvent",
                          event_category: "Form",
                          event_action: "Error",
                          event_label: "Falta completar Tercera pregunta",
                          interaction: true,
                          component_name: "boton_enviar",
                        });

                      validatedFields(errors, touched);
                    }

                    // console.log("valuews", values);
                    return (
                      <Form className={styles.formContainer} id="popupForm">
                        <h1 className={styles.title}>
                          Completá la encuesta y participá del sorteo de kits 27
                        </h1>

                        <Field
                          className={`${styles.inputForm} `}
                          name="fullName"
                          placeholder="Nombre y apellido"
                          onFocus={() => {
                            window.dataLayer.push({
                              event: "GAEvent",
                              event_category: "Form",
                              event_action: "Paso 1",
                              event_label: "Nombre y apellido",
                              interaction: true,
                              component_name: "boton_enviar",
                            });
                          }}
                          // onBlur={()=>{console.log("blur")}}
                        />
                        <Field
                          className={styles.inputForm}
                          id="date"
                          name="date"
                          type="text"
                          value={values.date}
                          // onChange={(e) => {
                          //   console.log("e", e.target.value)
                          //   const value = e.target.value || "";
                          //   const formated = formatDate(value);
                          //   setIsValidDate(validateDOB(formated));
                          //   setFieldValue("date", formated);
                          // }}
                          onChange={(e) => {
                            const { value } = e.target;
                            const formattedDate = formatDate(value);
                            setFieldValue("date", formattedDate);
                            // setIsValidDate(validateDOB(formattedDate));
                          }}
                          placeholder="Fecha de Nacimiento"
                          onFocus={() => {
                            window.dataLayer.push({
                              event: "GAEvent",
                              event_category: "Form",
                              event_action: "Paso 2",
                              event_label: "Fecha de nacimiento",
                              interaction: true,
                              component_name: "boton_enviar",
                            });
                          }}
                        />

                        <Field
                          className={styles.inputForm}
                          name="email"
                          type="email"
                          placeholder="Email"
                          onBlur={() => {
                            window.dataLayer.push({
                              event: "GAEvent",
                              event_category: "Form",
                              event_action: "Paso 3",
                              event_label: "Email",
                              interaction: true,
                              component_name: "boton_enviar",
                            });
                          }}
                        />
                        <Field
                          className={`${styles.inputForm} `}
                          name="instagram"
                          placeholder="Instagram"
                          onChange={({ target }) => {
                            if (target.value && !target.value.startsWith("@")) {
                              setFieldValue("instagram", `@${target.value}`);
                            } else {
                              setFieldValue("instagram", target.value);
                            }
                          }}
                          onFocus={() => {
                            window.dataLayer.push({
                              event: "GAEvent",
                              event_category: "Form",
                              event_action: "Paso 4",
                              event_label: "Instagram",
                              interaction: true,
                              component_name: "boton_enviar",
                            });
                          }}
                          // onBlur={()=>{console.log("blur")}}
                        />
                        <Field
                          name={"state"}
                          id={"state"}
                          field
                          component={SelectPopup}
                          as={"select"}
                          // className={"reactSelectContainer"}
                          className={styles.inputForm}
                          classNamePrefix={"reactSelect"}
                          isMobileDevice={isMobileDevice}
                          placeholder={"Provincia"}
                          onChange={(e) => {
                            setFieldValue("state", e.value);
                            setProvincia(e.label);
                          }}
                          onFocus={() => {
                            window.dataLayer.push({
                              event: "GAEvent",
                              event_category: "Form",
                              event_action: "Paso 5",
                              event_label: "Provincia",
                              interaction: true,
                              component_name: "boton_enviar",
                            });
                          }}
                          options={provincias.sort(function (a, b) {
                            return ("" + a.value).localeCompare(b.value);
                          })}
                        ></Field>

                        <Field
                          name={"localidad"}
                          id={"localidad"}
                          field
                          component={SelectPopup}
                          // className={"reactSelectContainer"}
                          className={styles.inputForm}
                          classNamePrefix={"reactSelect"}
                          isMobileDevice={isMobileDevice}
                          placeholder={"Localidad"}
                          onChange={(e) => {
                            setFieldValue("localidad", e.value);
                          }}
                          options={cities}
                          onFocus={() => {
                            window.dataLayer.push({
                              event: "GAEvent",
                              event_category: "Form",
                              event_action: "Paso 6",
                              event_label: "Localidad",
                              interaction: true,
                              component_name: "boton_enviar",
                            });
                          }}
                        />

                        <Field
                          name={"questOne"}
                          id={"questOne"}
                          field
                          component={SelectPopup}
                          className={styles.inputForm}
                          classNamePrefix={"reactSelect"}
                          isMobileDevice={isMobileDevice}
                          placeholder={
                            "¿Cuál es tu momento favorito para disfrutar una cerveza?"
                          }
                          onChange={(e) => {
                            setFieldValue("questOne", e.value);
                          }}
                          options={
                            isMobileDevice ? questCervezaMobile : questCerveza
                          }
                          onFocus={() => {
                            window.dataLayer.push({
                              event: "GAEvent",
                              event_category: "Form",
                              event_action: "Paso 7",
                              event_label:
                                "¿Cuál es tu momento favorito para disfrutar una cerveza?",
                              interaction: true,
                              component_name: "boton_enviar",
                            });
                          }}
                        />
                        {/* {errors.questOne && touched.questOne ? (
                        <div className={styles.errorContainer}>
                          {errors.questOne}
                        </div>2
                      ) : null} */}
                        <Field
                          name={"questTwo"}
                          id={"questTwo"}
                          field
                          component={SelectPopup}
                          // className={"reactSelectContainer"}
                          className={styles.inputForm}
                          classNamePrefix={"reactSelect"}
                          isMobileDevice={isMobileDevice}
                          placeholder={
                            "¿Sabes lo que es la agricultura regenerativa?"
                          }
                          onChange={(e) => {
                            setFieldValue("questTwo", e.value);
                          }}
                          options={[
                            {
                              value: "Si",
                              label: "Si",
                            },
                            {
                              value: "No",
                              label: "No",
                            },
                          ]}
                          onFocus={() => {
                            window.dataLayer.push({
                              event: "GAEvent",
                              event_category: "Form",
                              event_action: "Paso 8",
                              event_label:
                                "¿Sabes lo que es la agricultura regenerativa?",
                              interaction: true,
                              component_name: "boton_enviar",
                            });
                          }}
                        />
                        {/* {errors.questTwo && touched.questTwo ? (
                        <div className={styles.errorContainer}>
                          {errors.questTwo}
                        </div>
                      ) : null} */}
                        <Field
                          name={"questThree"}
                          id={"questThree"}
                          field
                          component={SelectPopup}
                          // className={"reactSelectContainer"}
                          className={styles.inputForm}
                          classNamePrefix={"reactSelect"}
                          isMobileDevice={isMobileDevice}
                          placeholder={"¿Reciclas las latas luego de tomarlas?"}
                          onChange={(e) => {
                            setFieldValue("questThree", e.value);
                          }}
                          options={[
                            {
                              value: "Siempre",
                              label: "Siempre",
                            },
                            {
                              value: "A veces",
                              label: "A veces",
                            },
                            {
                              value: "Nunca",
                              label: "Nunca",
                            },
                          ]}
                          onFocus={() => {
                            window.dataLayer.push({
                              event: "GAEvent",
                              event_category: "Form",
                              event_action: "Paso 9",
                              event_label:
                                "¿Reciclas las latas luego de tomarlas?",
                              interaction: true,
                              component_name: "boton_enviar",
                            });
                          }}
                        />
                        {/* {errors.questTwo && touched.questTwo ? (
                        <div className={styles.errorContainer}>
                          {errors.questTwo}
                        </div>
                      ) : null} */}

                        <div className={styles.checkboxContainer}>
                          <Checkbox27
                            errorTerms={errorTerms}
                            setErrorTerms={setErrorTerms}
                            terms={terms}
                            setTerms={setTerms}
                            popUp={true}
                            // onClick={handlerCheck}
                          />

                          {error ? (
                            <div className={styles.errorContainer}>
                              Faltan completar campos
                            </div>
                          ) : null}
                          {errorDate ? (
                            <div className={styles.errorContainer}>
                              Fecha inválida
                            </div>
                          ) : null}
                          {errorTerms && !terms ? (
                            <div className={styles.errorContainer}>
                              Los terminos y condiciones deben ser aceptados.
                            </div>
                          ) : null}
                        </div>
                        <button
                          className={styles.submitBtn}
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Enviando ... " : "Enviar"}
                        </button>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </div>
          </Modal>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
