import React from "react";
import styles from "./../styles/agegate.module.scss";
import { styled } from "@stitches/react";
import { CheckIcon } from "@radix-ui/react-icons";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import useDeviceDetect from "./../hooks/useDevice";
const StyledCheckbox = styled(CheckboxPrimitive.Root, {
    all: "unset",
    width: 25,
    height: 25,
    borderRadius: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // boxShadow: `0 2px 10px black`,
    border: `1.3px solid black`,
    "&:hover": { backgroundColor: "white" },
});

const StyledIndicator = styled(CheckboxPrimitive.Indicator, {
    color: "black",
});

// Exports
const Checkbox = StyledCheckbox;
const CheckboxIndicator = StyledIndicator;

// Your app...
const Flex = styled("div", { display: "flex" });

export default function CheckboxAgeGate({ remember, setRemember }) {
    const handlerCheck = () => {
        setRemember(!remember);
        if (!remember) {
            window.dataLayer.push({
                event: "GAEvent",
                event_category: "Age Gate",
                event_action: "Intercation",
                event_label: "Recuerdame",
                interaction: false,
                component_name: "",
                element_text: "",
            });
        }
    };
    const { isMobile } = useDeviceDetect();
    // console.log(`isMobile? ${isMobile}`)
    return (
        <Flex css={{ alignItems: "center", marginTop: "10px" }}>
            <Checkbox
                checked={remember}
                terms={remember}
                id="c1"
                value={remember}
                onClick={handlerCheck}
                name="checked"
                style={{
                    backgroundColor: "transparent",
                    height: isMobile ? "11px" : "25px",
                    width: isMobile ? "10px" : "25px",
                }}
            >
                <CheckboxIndicator>
                    <CheckIcon />
                </CheckboxIndicator>
            </Checkbox>
            <div htmlFor="c1" className={styles.checkbox}>
                <h3 className={styles.termsText}>Recordarme</h3>
            </div>
        </Flex>
    );
}
