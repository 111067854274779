import React from "react";
import styles from "./../styles/section.module.scss";
import stylesPopup from "./../styles/popup.module.scss";
import { styled } from "@stitches/react";
import { CheckIcon } from "@radix-ui/react-icons";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import useDeviceDetect from "./../hooks/useDevice";
const StyledCheckbox = styled(CheckboxPrimitive.Root, {
    all: "unset",
    width: 25,
    height: 25,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // boxShadow: `0 2px 10px black`,
    border: `1.3px solid #F3F1F0`,
    "&:hover": { backgroundColor: "white" },
});

const StyledIndicator = styled(CheckboxPrimitive.Indicator, {
    color: "black",
});

// Exports
const Checkbox = StyledCheckbox;
const CheckboxIndicator = StyledIndicator;

// Your app...
const Flex = styled("div", { display: "flex" });

export default function Checkbox27({
    errorTerms,
    setErrorTerms,
    terms,
    setTerms,
    popUp,
}) {
    const handlerCheck = () => {
        window.dataLayer.push({
            event: "GAEvent",
            event_category: "Form",
            event_action: "Paso 10",
            event_label: "tyc",
            interaction: true,
            component_name: "boton_enviar",
        });

        setErrorTerms(!errorTerms);
        setTerms(!terms);
    };

    const { isMobile } = useDeviceDetect();
    return (
        <Flex css={{ alignItems: "center" }}>
            <Checkbox
                checked={terms}
                terms={terms}
                id="c1"
                value={terms}
                onClick={handlerCheck}
                name="checked"
                style={{
                    backgroundColor: terms
                        ? "rgb(229, 228, 229)"
                        : "transparent",
                    height: isMobile ? "12px" : "16px",
                    width: isMobile ? "12px" : "16px",
                    border: "1px solid #292929",
                }}
            >
                <CheckboxIndicator>
                    <CheckIcon />
                </CheckboxIndicator>
            </Checkbox>
            <div
                htmlFor="c1"
                className={popUp ? stylesPopup.checkbox : styles.checkbox}
            >
                <h3 className={styles.termsText}>
                    He leído y acepto{" "}
                    <a
                        className={styles.link}
                        style={{ textDecoration: "underline" }}
                        target="_blank"
                        href="/Bases y Condiciones landing 27- Sorteos 2023.docx"
                    >
                        Bases y Condiciones
                    </a>
                    ,{" "}
                    <a
                        className={styles.link}
                        style={{ textDecoration: "underline" }}
                        target="_blank"
                        href="/PoliticasPrivacidad27.pdf"
                    >
                        Políticas de Privacidad
                    </a>{" "}
                    ,{" "}
                    <a
                        className={styles.link}
                        style={{
                            textDecoration: "underline",
                        }}
                        target="_blank"
                        href="/TerminosYCondiciones27.pdf"
                    >
                        Términos y condiciones
                    </a>
                    ,{"\n"}y activaciones de marketing.
                </h3>
            </div>
        </Flex>
    );
}
