import React from "react";
import Select from "react-select";

const SelectPopup = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors },
  ...props
}) => {
  const black = "#1a1a1a";
  const white = "#ffffff";
  const grey = "#292929";
  return (
    <Select
      // options={props.options}
      {...field}
      {...props}
      styles={{
        container: (baseStyles, state) => ({
          ...baseStyles,
          paddingRight: "0px !important",
          alignSelf: "center",
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          border: props.isMobileDevice ? "none" : "none",
          borderColor: "transparent",
          backgroundColor: "transparent",
          boxShadow: "none",
          boxSizing: "content-box",
          minWidth: "150px",
          minHeight: "40px",
          alignSelf: "center",
        }),
        // Menu opciones dropdown
        menu: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: black,
          border: `1px solid ${white}`,
          borderTop: `0px`,
          position: "absolute",
          top: "38.5px",
          left: props.isMobileDevice ? "-2.5px" : "-2.5px",
          width: props.isMobileDevice ? "102%" : "101%",
          // height: "100%",
          // borderBottom: `0px`,
          boxSizing: "border-box",
          alignSelf: "center",
          // paddingRight: "30px !important",
        }),
        menuList: (baseStyles, state) => ({
          ...baseStyles,
          padding: "0px",
          overflowY: "scroll"

        }),
        input: (baseStyles, state) => ({
          ...baseStyles,
          color: white,
          // paddingLeft: "350px",
          alignSelf: "center",
          paddingRight: "30px !important",
          display: "flex",
        }),
        // Valor
        singleValue: (baseStyles, state) => ({
          ...baseStyles,
          color: white,
          // paddingRight: "30px !important",
          display: "flex",
        }),
        // Separador vertical
        indicatorSeparator: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: white,
        }),
        // Flecha
        dropdownIndicator: (baseStyles, state) => ({
          ...baseStyles,
          color: white,
        }),
        placeholder: (baseStyles, state) => ({
          ...baseStyles,
          color: white,
        }),
        valueContainer: (baseStyles, state) => ({
          ...baseStyles,
          color: white,
          // display: 'flex',
          alignItems: "end",
          justifyItems: "center",
        }),
        // Texto de opcion select
        option: (baseStyles, state) => ({
          ...baseStyles,
          // maxWidth: props.isMobileDevice ? "250px" : "auto",
          display: "flex",
          justifyContent: "center",
          color: white,
          paddingRight: "35px !important",
          // borderBottom: `0.1px solid ${purple}`,
          backgroundColor: state.isActive ? black : grey,
          backgroundColor: state.isFocused ? grey : `${black} !important`,
        }),
      }}
      // className={styles.inputForm}
    />
  );
};

export default SelectPopup;
