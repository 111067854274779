import React, { useState, useRef, useEffect } from "react";
import styles from "./../styles/agegate.module.scss";
import Modal from "react-modal";
import useDeviceDetect from "./../hooks/useDevice";
import Cookies from "js-cookie";
import { Formik, Form, Field } from "formik";
import CheckboxAgeGate from "./CheckboxAgeGate";
const customStyles = {
    content: {
        // top: "50%",
        // left: "50%",
        // right: "auto",
        // bottom: "auto",
        // marginRight: "-50%",
        // transform: "translate(-50%, -50%)",
    },
};

export default function AgeGate({setIsLegalAge}) {

    const [modalIsOpen, setIsOpen] = useState(true);
    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const [remember, setRemember] = useState(false);
    const [isYounger, setIsYounger] = useState(false);
    const { isMobile } = useDeviceDetect();
    let [date, setDate] = useState({ dd: "", mm: "", aaaa: "" });
    const inputDay = useRef(null);
    const inputYear = useRef(null);
    const inputMonth = useRef(null);

    useEffect(() => {
        setIsMobileDevice(isMobile);
        if (Cookies.get("age")) {
            setIsOpen(false);
            setIsLegalAge(true)
        }

    }, []);

    useEffect(() => {
        if (
            date.dd.length === 2 &&
            date.mm.length === 2 &&
            date.aaaa.length === 4
        ) {
            window.dataLayer.push({
                event: "GAEvent",
                event_category: "Age Gate",
                event_action: "Intercation",
                event_label: "Yes",
                interaction: false,
                component_name: "",
                element_text: "",
            });
        }
    }, [date]);

    const handleAge = () => {
        setIsLegalAge(true)
        if (remember) {
            Cookies.set("age", true, { expires: 365 });
        } else {
            sessionStorage.setItem("age", true);
        }
    };

    const calculateAge = (birthday) => {
        let ageDate = new Date(Date.now() - birthday.getTime());
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    const onChange = (e) => {
        let { name, value } = e.target;

        if (e.target.name === "dd" && value.length >= 2) {
            value = value.slice(0, 2);
            inputMonth.current.focus();
        } else if (e.target.name === "mm") {
            if (value.length >= 2) {
                value = value.slice(0, 2);
                inputYear.current.focus();
            } else if (!value.length) {
                inputDay.current.focus();
            }
        } else {
            if (value.length > 4) value = value.slice(0, 4);
            else if (!value.length) inputMonth.current.focus();
        }

        setDate({ ...date, [name]: value });
    };

    function closeModal() {
        setIsOpen(false);
    }
    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className={styles.containerAG}
                contentLabel="Example Modal"
                shouldCloseOnEsc={false}
                shouldCloseOnOverlayClick={false}
                overlayClassName={styles.modalOverlay}
            >
                <div className={styles.imgBackground}>
                    <h1 className={styles.title}>¿Sos mayor de edad?</h1>
                    <Formik
                        initialValues={{
                            email: "",
                        }}
                        // validationSchema={SignupSchema}
                        // onSubmit={(values) => {
                        //   setIsOpen(false);
                        // }}
                        onSubmit={(values) => {
                            window.dataLayer.push({
                                event: "GAEvent",
                                event_category: "Age Gate",
                                event_action: "Intercation",
                                event_label: "Send",
                                interaction: false,
                                component_name: "",
                                element_text: "",
                            });
                            if (date.aaaa.length === 4 && date.dd && date.mm) {
                                let birthday = new Date();
                                birthday.setDate(parseInt(date.dd));
                                birthday.setMonth(parseInt(date.mm) - 1);
                                birthday.setFullYear(parseInt(date.aaaa));
                                if (date.aaaa && date.mm && date.dd) {
                                    if (calculateAge(birthday) >= 18) {
                                        sessionStorage.setItem("age", "true");
                                        handleAge();
                                        setIsOpen(false);
                                        window.dataLayer.push({
                                            event: "GAEvent",
                                            event_category: "Age Gate",
                                            event_action: "Intercation",
                                            event_label: "Yes",
                                            interaction: false,
                                            component_name: "",
                                            element_text: "",
                                        });
                                    } else {
                                        window.dataLayer.push({
                                            event: "GAEvent",
                                            event_category: "Age Gate",
                                            event_action: "Intercation",
                                            event_label: "No",
                                            interaction: false,
                                            component_name: "",
                                            element_text: "",
                                        });
                                        window.location.href =
                                            "https://www.poneloenpalabras.com.ar";
                                        setIsYounger(true);
                                    }
                                }
                            }
                        }}
                    >
                        <Form className={styles.formAG}>
                            <div className={styles.inputsContainer}>
                                <input
                                    maxLength={2}
                                    name={"dd"}
                                    onChange={onChange}
                                    placeholder="DD"
                                    value={date.dd}
                                    ref={inputDay}
                                    className={styles.input}
                                    type="number"
                                />
                                <input
                                    maxLength={2}
                                    name={"mm"}
                                    onChange={onChange}
                                    placeholder="MM"
                                    value={date.mm}
                                    ref={inputMonth}
                                    className={styles.input}
                                    type="number"
                                />
                                <input
                                    maxLength={4}
                                    name={"aaaa"}
                                    onChange={onChange}
                                    placeholder="AAAA"
                                    value={date.aaaa}
                                    ref={inputYear}
                                    type="number"
                                    className={styles.input}
                                />
                            </div>
                            {isYounger && (
                                <div>
                                    <p className={styles.youngerText}>
                                        Debes ser mayor de 18 años.
                                    </p>
                                </div>
                            )}
                            <button className={styles.submitAG} type="submit">
                                Aceptar
                            </button>
                            <CheckboxAgeGate
                                remember={remember}
                                setRemember={setRemember}
                            />
                        </Form>
                    </Formik>
                </div>
            </Modal>
        </>
    );
}
