import React from "react";
import styles from "./../../styles/popup.module.scss";
import sucessImg from "../../images/gracias.png"

export default function PopUpSuccess({ isMobile, closeModal }) {
  return (
    <div className={styles.successImageCont}>
      <img
        src={sucessImg}
        className={styles.successImage}
        alt="gracias"
        onClick={closeModal}></img>
      <h1 className={styles.successText}>GRACIAS!</h1>
    </div>
  );
}
